import React from "react";
import {Button, Modal } from "react-bootstrap";
import { Settings } from "../models/settings";
import { ResourceManager } from "../resources/resource-manager";
import { LocalStorageUtility } from "../utilities/LocalStorageUtility";
import "./ConfigModal.scss";

interface ConfigModalProperties {
    show: boolean;
    onClose: () => void;
    onSave: (settings: Settings) => void;
}

interface ConfigModalState {
    settings: Settings;
}

class ConfigModal extends React.Component<ConfigModalProperties, ConfigModalState>
{
    public componentDidMount(): void {
        const settings: Settings = LocalStorageUtility.getSettings();
        this.setState({settings});
    }

    public render(): JSX.Element {
        return (
            <Modal className="config-modal" show={this.props.show} onHide={() => { this.props.onClose(); }} backdrop centered>
                <Modal.Header>
                    <Modal.Title>{ResourceManager.strings.settings}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <label htmlFor="print-split-page-checkbox">{ResourceManager.strings.showTeamsOnDifferentPages}</label>
                    <input id="print-split-page-checkbox" className="form-control d-inline-block w-auto align-middle mx-2" type="checkbox" checked={this.state?.settings?.showTeamsOnDifferentPages}
                           onChange={ (event) => this.onChangeShowTeamsOnDifferentPages(event.target.checked) } />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.props.onClose(); }}>{ResourceManager.strings.close}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private onChangeShowTeamsOnDifferentPages(showTeamsOnDifferentPages: boolean): void {
        this.setState({
            settings: {
                ...this.state.settings,
                showTeamsOnDifferentPages: showTeamsOnDifferentPages
            }
        }, () => {
            this.saveSettings();
        });
    }

    private saveSettings(): void {
        LocalStorageUtility.saveSettings(this.state.settings);
        this.props.onSave(this.state.settings);
    }
}

export default ConfigModal;