import {Player} from "../models/player";

export class FileUtility
{
    public static loadPlayerListFromJsonFile(jsonFile: File, onLoadCallback: (players: Player[]) => void): void
    {
        if (jsonFile) {
            try {
                const reader = new FileReader();

                reader.onloadend = () => {
                    onLoadCallback(JSON.parse(reader.result + ""));
                };

                reader.readAsText(jsonFile);
            }
            catch {}
        }
    }
}