import ResourceStrings from "./resources";

class ResourceStringsFr extends ResourceStrings {
    defense: string = "Défenseur";
    forward: string = "Attaquant";
    id: string = "Identifiant";
    name: string = "Nom";
    skill: string = "Talent";
    position: string = "Position";
    shuffleTeams: string = "Mélanger les équipes";
    delete: string = "Supprimer";
    add: string = "Ajouter";
    new = "Nouveau";
    save: string = "Sauvegarder";
    load: string = "Charger";
    print: string = "Imprimer";
    showSelectedPlayerOnly: string = "Afficher seulement les joueurs sélectionnés";
    pairing: string = "Jumelage";
    goalie: string = "Gardien";
    shufflePlayersIntoTwoTeams:string = "Mélanger les joueurs sélectionnés en deux équipes";
    savePlayersList:string = "Sauvegarder la liste de joueurs dans un fichier";
    loadPlayersList:string = "Charger une liste de joueurs à partir d'un fichier";
    printGeneratedTeams:string = "Imprimer les équipes générées";
    changeInterfaceLanguage:string = "Changer la langue d'interface";
    sortPlayersByName: string = "Trier les joueurs par nom";
    sortPlayersByPosition: string = "Trier les joueurs par position";
    sortPlayersBySkill: string = "Trier les joueurs par talent";
    sortPlayersByPairing: string = "Trier les joueurs par jumelage";
    selectPlayer: string = "Choisir ce joueur";
    playerName: string = "Définir le nom du joueur";
    playerPosition: string = "Définir la position du joueur";
    playerSkill: string = "Définir le talent du joueur";
    pairPlayersWithTheSameNumber: string = "Jumeler les joueurs avec le même numéro dans la même équipe";
    addThisPlayer: string = "Ajouter ce joueur";
    deleteThisPlayer: string = "Supprimer ce joueur";
    teamName: string = "Définir le nom de l'équipe";
    copyTeamToClipboardForExcel: string = "Copier l'équipe dans le presse papier pour Excel";
    teamTotalSkill: string = "Talent total de l'équipe";
    cancel: string = "Annuler";
    close: string = "Fermer";
    settings: string = "Paramètres";
    fileName: string = "Nom du fichier";
    showTeamsOnDifferentPages: string = "Afficher les équipes sur des pages différentes";
    switchPlayerTeam: string = "Changer le joueur d'équipe";
    invalidGoalieNumber: string = "Exactement 2 gardiens sont requis";
    clipboard: string = "Presse-Papiers";
}

export default ResourceStringsFr;