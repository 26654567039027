import {Player, PlayerPosition, PlayerSkill} from "../models/player";
import {PlayerUtility} from "./PlayerUtility";

export class TeamShuffleUtility {
    public static shuffle(fowards: Player[], defences: Player[], goalies?: Player[]): TeamShuffleResult {
        let result: TeamShuffleResult = { team1: [], team2: [], showGoalieErrorMessage: false };
        let randomizedForwards: Player[] = PlayerUtility.getRandomizedPlayerList(fowards);
        let randomizedDefences: Player[] = PlayerUtility.getRandomizedPlayerList(defences);
        let sortedForwards: Player[] = PlayerUtility.getSortedPlayerListBySkill(randomizedForwards, "ASC");
        let sortedDefences: Player[] = PlayerUtility.getSortedPlayerListBySkill(randomizedDefences, "ASC");

        TeamShuffleUtility.sharePlayersInTwoTeams(sortedForwards, result.team1, result.team2);
        TeamShuffleUtility.sharePlayersInTwoTeams(sortedDefences, result.team1, result.team2);

        if (goalies && goalies.length === 2) {
            TeamShuffleUtility.sharePlayersInTwoTeams(goalies, result.team1, result.team2);
        }
        else {
            result.showGoalieErrorMessage = true;
        }

        if (Math.abs(result.team1.length - result.team2.length) > 1) {
            TeamShuffleUtility.balanceTeamsPlayerNumber(result.team1, result.team2);
        }

        let team1SkillSum: number = PlayerUtility.getPlayerSkillSumFromList(result.team1);
        let team2SkillSum: number = PlayerUtility.getPlayerSkillSumFromList(result.team2);
        let handicap: number = result.team1.length !== result.team2.length ? Math.ceil(Math.max(team1SkillSum, team2SkillSum) / Math.max(result.team1.length, result.team2.length)) : 0;

        if (result.team1.length === result.team2.length && team1SkillSum < team2SkillSum) {
            TeamShuffleUtility.balanceTeams(result.team1, result.team2);
        }
        else if (result.team1.length === result.team2.length && team1SkillSum > team2SkillSum) {
            TeamShuffleUtility.balanceTeams(result.team2, result.team1);
        }
        else if (result.team1.length > result.team2.length && team1SkillSum > team2SkillSum + handicap) {
            TeamShuffleUtility.balanceTeams(result.team2, result.team1, handicap);
        }
        else if (result.team1.length > result.team2.length && team1SkillSum < team2SkillSum + handicap) {
            TeamShuffleUtility.balanceTeams(result.team1, result.team2, handicap);
        }
        else if (result.team1.length < result.team2.length && team2SkillSum > team1SkillSum + handicap) {
            TeamShuffleUtility.balanceTeams(result.team1, result.team2, handicap);
        }
        else if (result.team1.length < result.team2.length && team2SkillSum < team1SkillSum + handicap) {
            TeamShuffleUtility.balanceTeams(result.team2, result.team1, handicap);
        }

        result.team1 = PlayerUtility.getSortedPlayerListBySkill(result.team1, "ASC");
        result.team2 = PlayerUtility.getSortedPlayerListBySkill(result.team2, "ASC");

        return result;
    }

    private static sharePlayersInTwoTeams(players: Player[], team1: Player[], team2: Player[]): void {
        let addToTeam1: boolean = TeamShuffleUtility.getRandomBoolean();

        for (let player of players) {
            if ((player.pairing && team1.filter(team1Player => team1Player.pairing === player.pairing).length > 0) ||
                ((!player.pairing || (team2.filter(team2Player => team2Player.pairing === player.pairing).length === 0 &&
                    new Set(team1.filter(player => player.pairing).map(player => player.pairing)).size < 2)) && addToTeam1)) {
                team1.push(player);
            }
            else {
                team2.push(player);
            }

            addToTeam1 = team1.length <= team2.length;
        }
    }

    private static balanceTeamsPlayerNumber(team1: Player[], team2: Player[]): void {
        const biggestTeam: Player[] = team1.length > team2.length ? team1 : team2;
        const smallestTeam: Player[] = team1.length < team2.length ? team1 : team2;
        const fowardPlayerDifference: number = biggestTeam.filter(player => player.position === PlayerPosition.Foward).length -
            smallestTeam.filter(player => player.position === PlayerPosition.Foward).length;
        const defensePlayerDifference: number = biggestTeam.filter(player => player.position === PlayerPosition.Defense).length -
            smallestTeam.filter(player => player.position === PlayerPosition.Defense).length;

        if (fowardPlayerDifference >= defensePlayerDifference)
            this.movePlayerFromTeamToAnother(PlayerUtility.getRandomPlayerInList(biggestTeam, PlayerPosition.Foward), biggestTeam, smallestTeam);
        else
            this.movePlayerFromTeamToAnother(PlayerUtility.getRandomPlayerInList(biggestTeam, PlayerPosition.Defense), biggestTeam, smallestTeam);
    }

    private static movePlayerFromTeamToAnother(player: Player | undefined, sourceTeam: Player[], destinationTeam: Player[]): void {
        if (player) {
            sourceTeam.splice(sourceTeam.indexOf(player), 1);
            destinationTeam.push(player);
        }
    }

    private static balanceTeams(strongestTeam: Player[], weakestTeam: Player[], handicap:number = 0): void {
        let maxChanges: number = 20;
        let strongestTeamSkillSum: number;
        let weakestTeamSkillSum: number;

        do
        {
            TeamShuffleUtility.switchTwoCloseSkillPlayers(strongestTeam, weakestTeam);
            strongestTeamSkillSum = PlayerUtility.getPlayerSkillSumFromList(strongestTeam);
            weakestTeamSkillSum = PlayerUtility.getPlayerSkillSumFromList(weakestTeam);
        }
        while(strongestTeamSkillSum + (strongestTeam.length < weakestTeam.length ? handicap : 0) < weakestTeamSkillSum + (strongestTeam.length > weakestTeam.length ? handicap : 0) && maxChanges-- > 0)
    }

    private static switchTwoCloseSkillPlayers(strongestTeam: Player[], weakestTeam: Player[]): void {
        let switchFowardPlayer: boolean = TeamShuffleUtility.getRandomBoolean();

        let strongestTeamPlayer: Player | undefined = PlayerUtility.getRandomPlayerInList(strongestTeam, switchFowardPlayer ? PlayerPosition.Foward : PlayerPosition.Defense);
        let weakestTeamPlayerWithLessSkill: Player | undefined = PlayerUtility.getRandomPlayerInList(weakestTeam, switchFowardPlayer ? PlayerPosition.Foward : PlayerPosition.Defense, strongestTeamPlayer?.skill && strongestTeamPlayer.skill + 1 as PlayerSkill);

        if (strongestTeamPlayer && weakestTeamPlayerWithLessSkill) {
            weakestTeam.push(strongestTeam.splice(strongestTeam.indexOf(strongestTeamPlayer), 1)[0]);
            strongestTeam.push(weakestTeam.splice(weakestTeam.indexOf(weakestTeamPlayerWithLessSkill), 1)[0]);
        }
    }

    private static getRandomBoolean(): boolean {
        return Math.random() >= 0.5;
    }
}

export interface TeamShuffleResult {
    team1: Player[];
    team2: Player[];
    showGoalieErrorMessage: boolean;
}