import {Player} from "../models/player";
import {DEFAULT_SETTINGS, Settings } from "../models/settings";
import {ResourceManager} from "../resources/resource-manager";

export class LocalStorageUtility
{
    public static savePlayersToLocalStorage(players: Player[]): void
    {
        window.localStorage.setItem("players", JSON.stringify(players));
    }

    public static loadPlayersFromLocalStorage(): Player[]
    {
        const playerStorageValue: string | null = window.localStorage.getItem("players");
        return playerStorageValue ? JSON.parse(playerStorageValue) : [];
    }

    public static getCurrentLanguage(): string
    {
        return window.localStorage.getItem("currentLanguage") || ResourceManager.INITIAL_LANGUAGE;
    }

    public static setCurrentLanguage(language: string): void
    {
        window.localStorage.setItem("currentLanguage", language);
    }

    public static getTeam1Name(): string {
        return window.localStorage.getItem("team1Name") || "1";
    }

    public static setTeam1Name(name: string) {
        window.localStorage.setItem("team1Name", name);
    }

    public static getTeam2Name(): string {
        return window.localStorage.getItem("team2Name") || "2";
    }

    public static setTeam2Name(name: string) {
        window.localStorage.setItem("team2Name", name);
    }

    public static getSettings(): Settings {
        const settings: string | null = window.localStorage.getItem("settings");
        return settings ? JSON.parse(settings) : DEFAULT_SETTINGS;
    }

    public static saveSettings(settings: Settings): void
    {
        window.localStorage.setItem("settings", JSON.stringify(settings));
    }
}