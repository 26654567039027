import ResourceStrings from "./resources";

class ResourceStringsEn extends ResourceStrings {
    defense: string = "Defense";
    forward: string = "Forward";
    id: string = "ID";
    name: string = "Name";
    skill: string = "Skill";
    position: string = "Position";
    shuffleTeams: string = "Shuffle teams";
    delete: string = "Delete";
    add: string = "Add";
    new = "New";
    save: string = "Save";
    load: string = "Load";
    print: string = "Print";
    showSelectedPlayerOnly: string = "Show selected players only";
    pairing: string = "Pairing";
    goalie: string = "Goalie";
    shufflePlayersIntoTwoTeams:string = "Shuffle selected players into two teams";
    savePlayersList:string = "Save players list on a file";
    loadPlayersList:string = "Load players list from a file";
    printGeneratedTeams:string = "Print generated teams";
    changeInterfaceLanguage:string = "Change interface language";
    sortPlayersByName: string = "Sort players by name";
    sortPlayersByPosition: string = "Sort players by position";
    sortPlayersBySkill: string = "Sort players by skill";
    sortPlayersByPairing: string = "Sort players by pairing";
    selectPlayer: string = "Select player";
    playerName: string = "Set player name";
    playerPosition: string = "Set player position";
    playerSkill: string = "Set player skill";
    pairPlayersWithTheSameNumber: string = "Pair players with the same number in the same team";
    addThisPlayer: string = "Add this player";
    deleteThisPlayer: string = "Delete this player";
    teamName: string = "Set team name";
    copyTeamToClipboardForExcel: string = "Copy team to clipboard for Excel";
    teamTotalSkill: string = "Team total skill";
    cancel: string = "Cancel";
    close: string = "Close";
    settings: string = "Settings";
    fileName: string = "File name";
    showTeamsOnDifferentPages: string = "Show teams on different pages";
    switchPlayerTeam: string = "Switch player to other team";
    invalidGoalieNumber: string = "Exactly 2 goalies are required";
    clipboard: string = "Clipboard";
}

export default ResourceStringsEn;