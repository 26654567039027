import React from 'react';
import './App.scss';
import {Player, PlayerPosition} from "./models/player";
import {PlayerUtility} from "./utilities/PlayerUtility";
import PlayerTable from "./components/PlayerTable";
import {LocalStorageUtility} from "./utilities/LocalStorageUtility";
import {FileUtility} from "./utilities/FileUtility";
import {ResourceManager} from "./resources/resource-manager";
import {TeamShuffleResult, TeamShuffleUtility} from "./utilities/TeamShuffleUtility";
import PlayerOutputTable from "./components/PlayerOutputTable";
import {Alert, Button, Modal, SplitButton} from "react-bootstrap";
import ConfigModal from './components/ConfigModal';
import { Gear } from 'react-bootstrap-icons';
import { DEFAULT_SETTINGS, Settings } from './models/settings';
import DropdownItem from "react-bootstrap/DropdownItem";

interface AppProperties {
}

interface AppState {
    players: Player[];
    nameSort: "ASC" | "DESC";
    positionSort: "ASC" | "DESC";
    skillSort: "ASC" | "DESC";
    team1Name: string;
    team2Name: string;
    team1: Player[];
    team2: Player[];
    language: string;
    showSelectedPlayersOnly: boolean;
    showSaveModal: boolean;
    showConfigModal: boolean;
    showGoalieErrorMessage: boolean;
    settings: Settings;
    saveFileName: string;
}

class App extends React.Component<AppProperties, AppState> {
    private readonly loadFileInput: React.RefObject<HTMLInputElement> = React.createRef();
    private readonly downloadAnchorElement: React.RefObject<HTMLAnchorElement> = React.createRef();

    constructor(props: AppProperties) {
        super(props);

        this.state = {
            players: LocalStorageUtility.loadPlayersFromLocalStorage(),
            nameSort: "ASC",
            positionSort: "ASC",
            skillSort: "ASC",
            team1Name: LocalStorageUtility.getTeam1Name(),
            team2Name: LocalStorageUtility.getTeam2Name(),
            team1: [],
            team2: [],
            language: ResourceManager.currentLanguage,
            showSelectedPlayersOnly: false,
            showSaveModal: false,
            showConfigModal: false,
            showGoalieErrorMessage: false,
            settings: DEFAULT_SETTINGS,
            saveFileName: `${new Date().toISOString().substring(0, 10)} lineup.json`
        };
    }

    public componentDidMount(): void {
        this.setState({ settings: LocalStorageUtility.getSettings() });
    }

    public render(): JSX.Element {
        return (
            <>
                {
                    this.state.settings.showTeamsOnDifferentPages &&
                    <style type="text/css" media="print">
                        {"@page { size: landscape; }"}
                    </style>
                }
                <Alert
                    dismissible={true}
                    show={this.state.showGoalieErrorMessage}
                    variant="danger"
                    onClose={() => {
                        this.setState({showGoalieErrorMessage: false})
                    }}
                >
                    {ResourceManager.strings.invalidGoalieNumber}
                </Alert>
                <div className="PlayerTable container-fluid">
                    <div className="row">
                        <div className="col-lg-6 d-print-none">
                            <div className="row my-2">
                                <div className="col">
                                    <button className="btn btn-primary m-2" title={ResourceManager.strings.shufflePlayersIntoTwoTeams} onClick={() => this.shuffleTeams()}>{ResourceManager.strings.shuffleTeams}</button>
                                    <button className="btn btn-secondary m-2" title={ResourceManager.strings.savePlayersList} onClick={() => this.setState({showSaveModal: true})}>{ResourceManager.strings.save}</button>
                                    <SplitButton id="asdasd" title={ResourceManager.strings.load} onClick={() => this.loadFileInput.current!.click()}>
                                        <DropdownItem onClick={() => { this.loadPlayersFromClipBoard() }}>{ResourceManager.strings.clipboard}</DropdownItem>
                                    </SplitButton>
                                    <input className="d-none" type="file" ref={this.loadFileInput} accept="application/json" onChange={ () => this.loadPlayersFromFiles() }/>
                                    <button className="btn btn-secondary m-2" title={ResourceManager.strings.printGeneratedTeams} onClick={() => this.onClickPrint()}>{ResourceManager.strings.print}</button>
                                    <button className="btn btn-secondary m-2" title={ResourceManager.strings.settings} onClick={() => { this.setState({ showConfigModal: true });}}>
                                        <Gear/>
                                    </button>
                                    <select value={this.state.language} title={ResourceManager.strings.changeInterfaceLanguage} className="form-control w-auto p-0 d-inline-block align-middle" onChange={(event) => this.onChangeLanguage(event.target.value)}>
                                        <option value={ResourceManager.ENGLISH_LANGUAGE}>English</option>
                                        <option value={ResourceManager.FRENCH_LANGUAGE}>Français</option>
                                    </select>
                                    <div className="d-inline m-2 text-nowrap" title={ResourceManager.strings.showSelectedPlayerOnly}>
                                        <input id="selected-players-only-checkbox" className="form-control d-inline-block w-auto align-middle mx-2" type="checkbox" checked={this.state.showSelectedPlayersOnly}
                                               onChange={ (event) => this.setState({showSelectedPlayersOnly: event.target.checked })} />
                                        <label className="d-inline-block" htmlFor="selected-players-only-checkbox">{ResourceManager.strings.showSelectedPlayerOnly}</label>
                                    </div>
                                </div>
                            </div>
                            <PlayerTable tableName="all" editable={true} selectable={true} showSelectedPlayerOnly={this.state.showSelectedPlayersOnly} players={this.state.players} onChangePlayerList={(players: Player[]) => this.savePlayers(players)}/>
                        </div>
                        <div className="col-lg-6 col-print-12">
                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.team1?.length > 0 &&
                                            <PlayerOutputTable team={this.state.team1} teamName={this.state.team1Name} onChangeTeam={(players: Player[]) => this.setState({team1: players})} onChangeTeamName={(teamName: string) => this.onChangeTeam1Name(teamName)} switchSide={"Down"} onSwitchPlayer={(player: Player) => { this.switchPlayerFromTeam1ToTeam2(player)}} />
                                    }
                                </div>
                            </div>
                            <div className={"row" + (this.state.settings.showTeamsOnDifferentPages ? " pagebreak" : "")}>
                                <div className="col">
                                    {
                                        this.state.team2?.length > 0 &&
                                            <PlayerOutputTable team={this.state.team2} teamName={this.state.team2Name} onChangeTeam={(players: Player[]) => this.setState({team2: players})} onChangeTeamName={(teamName: string) => this.onChangeTeam2Name(teamName)} switchSide={"Up"} onSwitchPlayer={(player: Player) => { this.switchPlayerFromTeam2ToTeam1(player)}} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal show={this.state.showSaveModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{ResourceManager.strings.savePlayersList}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <label htmlFor="saveFileNameInput">{ResourceManager.strings.fileName}</label>
                            <input id="saveFileNameInput" className="form-control" type="text" value={this.state.saveFileName} onChange={ (event) => this.setState({saveFileName: event.target.value}) }/>
                            <a className="d-none" download={this.state.saveFileName} href={"data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.state.players))} ref={this.downloadAnchorElement}>{ResourceManager.strings.save}</a>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {this.setState({showSaveModal: false})}}>
                                {ResourceManager.strings.cancel}
                            </Button>
                            <Button variant="primary"
                                    onClick={() => {
                                        this.downloadAnchorElement.current!.click();
                                        this.setState({showSaveModal: false});
                                    }}>
                                {ResourceManager.strings.save}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <ConfigModal
                        show={this.state.showConfigModal}
                        onClose={() => { this.setState({ showConfigModal: false }); }}
                        onSave={(settings: Settings) => { this.setState({ settings }); }}
                    />
                </div>
            </>
        );
    }

    private shuffleTeams(): void {
        const selectedForwards: Player[] | undefined = this.state.players.filter(player => player.selected && player.position === PlayerPosition.Foward);
        const selectedDefences: Player[] | undefined = this.state.players.filter(player => player.selected && player.position === PlayerPosition.Defense);
        const selectedGoalies: Player[] | undefined = this.state.players.filter(player => player.selected && player.position === PlayerPosition.Goalie);

        if ((selectedForwards && selectedForwards.length > 0) || (selectedDefences && selectedDefences.length > 0)) {
            let result: TeamShuffleResult = TeamShuffleUtility.shuffle(selectedForwards, selectedDefences, selectedGoalies);

            this.setState({
                team1: PlayerUtility.getSortedPlayerListByPosition(PlayerUtility.getSortedPlayerListBySkill(result.team1, "ASC"), "ASC"),
                team2: PlayerUtility.getSortedPlayerListByPosition(PlayerUtility.getSortedPlayerListBySkill(result.team2, "ASC"), "ASC"),
                showGoalieErrorMessage: result.showGoalieErrorMessage
            });
        }
    }

    private savePlayers(players: Player[]): void {
        this.setState({players: players},
            () => {
                LocalStorageUtility.savePlayersToLocalStorage(this.state.players);
            });
    }

    private loadPlayersFromFiles(): void {
        let uploadedFile = this.loadFileInput.current!.files![0];
        FileUtility.loadPlayerListFromJsonFile(uploadedFile, (players: Player[])=> { this.loadPlayers(players); });
    }

    private loadPlayersFromClipBoard(): void {
        try {
            navigator.clipboard.readText().then((json) => { this.loadPlayers(JSON.parse(json))});
        } catch {
            //ignore
        }
    }

    private loadPlayers(players: Player[]): void {
        this.setState({ players },
            () => {
                window.localStorage.setItem("players", JSON.stringify(this.state.players));
            });
    }
    
    private onChangeLanguage(language: string): void {
        ResourceManager.changeLanguage(language);
        this.setState({language: language});
    }

    private onChangeTeam1Name(name: string): void {
        this.setState({team1Name: name}, () => LocalStorageUtility.setTeam1Name(name));
    }

    private onChangeTeam2Name(name: string): void {
        this.setState({team2Name: name}, () => LocalStorageUtility.setTeam2Name(name));
    }

    private onClickPrint(): void {
        this.setState({
            team1: PlayerUtility.getSortedPlayerListByPosition(PlayerUtility.getRandomizedPlayerList(this.state.team1), "ASC"),
            team2: PlayerUtility.getSortedPlayerListByPosition(PlayerUtility.getRandomizedPlayerList(this.state.team2), "ASC"),
        }, () => {
            window.print();
        });
    }

    private switchPlayerFromTeam1ToTeam2(player: Player): void {
        this.setState({
            team1: this.state.team1.filter(team1Player => team1Player.id !== player.id),
            team2: this.state.team2.concat([player])
        });
    }

    private switchPlayerFromTeam2ToTeam1(player: Player): void {
        this.setState({
            team2: this.state.team2.filter(team1Player => team1Player.id !== player.id),
            team1: this.state.team1.concat([player])
        });
    }
}

export default App;
