export enum PlayerPosition
{
    Foward = 0,
    Defense = 1,
    Goalie = 2
}

export type PlayerSkill = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type PlayerPairing = 1 | 2 | 3 | 4 | null;

export interface Player
{
    id: number;
    name: string;
    skill: PlayerSkill;
    position: PlayerPosition;
    pairing?: PlayerPairing
    selected: boolean;
}