import React from "react";
import {Player, PlayerPairing, PlayerPosition, PlayerSkill} from "../models/player";
import {ButtonGroup, ToggleButton} from "react-bootstrap";
import "./PlayerTableRow.scss"
import {ResourceManager} from "../resources/resource-manager";
import {ArrowDown, ArrowUp} from "react-bootstrap-icons";

export type SwitchPlayerSide = "Up" | "Down";

interface PlayerTableRowProperties {
    selectable?: boolean;
    editable?: boolean;
    switchable?: boolean;
    switchSide?: SwitchPlayerSide;
    rowNumber?: string;
    player: Player;
    availablePairingValues?: PlayerPairing[];
    onSelectPlayer?: (checked: boolean) => void;
    onChangePlayerName?: (newName: string) => void;
    onChangePlayerPosition?: (newPosition: PlayerPosition) => void;
    onChangePlayerSkill?: (newSkill: PlayerSkill) => void;
    onChangePlayerPairing?: (newPairing: PlayerPairing) => void;
    onAddPlayer?: () => void;
    onDeletePlayer?: () => void;
    onSwitchPlayer?: () => void;
}

interface PlayerTableRowState {

}

class PlayerTableRow extends React.Component<PlayerTableRowProperties, PlayerTableRowState>
{
    public render(): JSX.Element {
        return (
            <tr key={`player-${this.props.player.id}`} className={`player-table-row ${this.props.player.position === PlayerPosition.Defense ? "player-table-row-defense" : ""}`}>
                {
                    this.props.rowNumber &&
                        <td className="d-print-none row-number-cell">{this.props.rowNumber}</td>
                }
                {
                    this.props.selectable &&
                    <td className="select-column">
                        {
                            !this.props.onAddPlayer && <input className="form-control" title={ResourceManager.strings.selectPlayer} type="checkbox" checked={this.props.player.selected}
                                   onChange={(event) => this.props.onSelectPlayer && this.props.onSelectPlayer(event.target.checked)}/>
                        }
                    </td>
               }
                <td className="w-50">
                    {
                        this.props.editable ?
                            <input className="form-control" type="text" value={this.props.player.name} title={ResourceManager.strings.playerName}
                                   onChange={ (event) => this.props.onChangePlayerName && this.props.onChangePlayerName(event.target.value) }/> :
                            this.props.player.name
                    }
                </td>
                <td>
                    {
                        this.props.editable ?
                            <ButtonGroup size="sm" toggle  title={ResourceManager.strings.playerPosition}>
                                <ToggleButton className="toggle-btn btn-secondary" type="radio" value={PlayerPosition.Foward} checked={this.props.player.position === PlayerPosition.Foward} onChange={() => this.props.onChangePlayerPosition && this.props.onChangePlayerPosition(PlayerPosition.Foward)}>{ResourceManager.strings.forward}</ToggleButton>
                                <ToggleButton className="toggle-btn btn-secondary" type="radio" value={PlayerPosition.Defense} checked={this.props.player.position === PlayerPosition.Defense} onChange={() => this.props.onChangePlayerPosition && this.props.onChangePlayerPosition(PlayerPosition.Defense)}>{ResourceManager.strings.defense}</ToggleButton>
                                <ToggleButton className="toggle-btn btn-secondary" type="radio" value={PlayerPosition.Goalie} checked={this.props.player.position === PlayerPosition.Goalie} onChange={() => this.props.onChangePlayerPosition && this.props.onChangePlayerPosition(PlayerPosition.Goalie)}>G</ToggleButton>
                            </ButtonGroup> :
                            this.props.player.position === PlayerPosition.Foward ? ResourceManager.strings.forward : (this.props.player.position === PlayerPosition.Defense ? ResourceManager.strings.defense : ResourceManager.strings.goalie)
                    }
                </td>
                <td className="d-print-none">
                    {
                        this.props.editable ?
                            <select className="form-control p-0" value={this.props.player.skill}
                                    title={ResourceManager.strings.playerSkill}
                                    onChange={(event) => this.props.onChangePlayerSkill && this.props.onChangePlayerSkill(parseInt(event.target.value) as PlayerSkill)}>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                            </select> :
                            this.props.player.skill
                    }
                </td>
                {
                this.props.editable &&
                    <td>
                        <select className="form-control p-0" title={ResourceManager.strings.pairPlayersWithTheSameNumber} disabled={!this.props.availablePairingValues || (!this.props.player.pairing && this.props.availablePairingValues.length <= 0)} value={this.props.player.pairing ? this.props.player.pairing.toString() : ""} onChange={(event) => this.props.onChangePlayerPairing && this.props.onChangePlayerPairing(event.target.value ? parseInt(event.target.value) as PlayerPairing : null)}>
                            <option value=""/>
                            {
                                this.props.availablePairingValues && this.props.availablePairingValues.map(pairingValue => <option key={`player-${this.props.player.id}-pairing-value-${pairingValue}`} value={pairingValue!.toString()}>{pairingValue}</option>)
                            }
                        </select>
                    </td>
                }
                {
                    this.props.switchable && this.props.onSwitchPlayer && this.props.switchSide &&
                    <td className="d-print-none"><button className="btn btn-secondary" title={ResourceManager.strings.switchPlayerTeam} onClick={() => this.props.onSwitchPlayer!()}>{ this.props.switchSide === "Up" ? <ArrowUp /> : <ArrowDown />}</button></td>
                }
                {
                    this.props.editable && this.props.onAddPlayer &&
                    <td><button className="btn btn-secondary" title={ResourceManager.strings.addThisPlayer} disabled={!this.props.player.name} onClick={() => this.props.onAddPlayer!()}>{ResourceManager.strings.add}</button></td>
                }
                {
                    this.props.editable && this.props.onDeletePlayer &&
                    <td><button className="btn btn-danger" title={ResourceManager.strings.deleteThisPlayer} onClick={() => this.props.onDeletePlayer!()}>{ResourceManager.strings.delete}</button></td>
                }
            </tr>
        );
    }
}

export default PlayerTableRow;