import ResourceStrings from "./resources";
import ResourceStringsEn from "./resources.en";
import ResourceStringsFr from "./resources.fr";
import {LocalStorageUtility} from "../utilities/LocalStorageUtility";

export class ResourceManager {
    public static readonly ENGLISH_LANGUAGE: string = "en";
    public static readonly FRENCH_LANGUAGE: string = "fr";
    public static readonly INITIAL_LANGUAGE: string = ResourceManager.ENGLISH_LANGUAGE;

    public static currentLanguage: string = LocalStorageUtility.getCurrentLanguage();
    public static strings: ResourceStrings = ResourceManager.getTranslatedStrings(ResourceManager.currentLanguage);

    public static changeLanguage(language: string): void {
        ResourceManager.strings = ResourceManager.getTranslatedStrings(language);
        LocalStorageUtility.setCurrentLanguage(language);
    }

    private static getTranslatedStrings(language: string): ResourceStrings {
        switch (language) {
            case ResourceManager.FRENCH_LANGUAGE:
                return new ResourceStringsFr();
            default:
                return new ResourceStringsEn();
        }
    }
}