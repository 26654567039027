import {Player, PlayerPairing, PlayerPosition, PlayerSkill} from "../models/player";
import {SortValue} from "../components/PlayerTable";

export class PlayerUtility
{
    public static getRandomizedPlayerList(playerList: Player[]): Player[]
    {
        let shuffledPlayerList: Player[] = playerList.slice(0);
        let currentIndex:number = shuffledPlayerList.length;
        let temporaryPlayer: Player;
        let randomIndex:number;

        while (0 !== currentIndex)
        {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryPlayer = shuffledPlayerList[currentIndex];
            shuffledPlayerList[currentIndex] = shuffledPlayerList[randomIndex];
            shuffledPlayerList[randomIndex] = temporaryPlayer;
        }

        return shuffledPlayerList;
    }

    public static getSortedPlayerListByName(playerList: Player[], sortOrder: SortValue): Player[]
    {
        let players: Player[] = playerList.slice(0);

        players.sort((player1, player2) =>
        {
            const player1Name = player1.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            const player2Name = player2.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if(player1Name < player2Name)
                return sortOrder === "ASC" ? -1 : 1;
            else if(player1Name > player2Name)
                return sortOrder === "ASC" ? 1 : -1;
            else return 0
        });

        return players;
    }

    public static getSortedPlayerListByPosition(playerList: Player[], sortOrder: SortValue): Player[]
    {
        let players: Player[] = playerList.slice(0);

        players.sort((player1, player2) =>
        {
            if(player1.position < player2.position)
                return sortOrder === "ASC" ? -1 : 1;
            else if(player1.position > player2.position)
                return sortOrder === "ASC" ? 1 : -1;
            else return 0
        });

        return players;
    }

    public static getSortedPlayerListBySkill(playerList: Player[], sortOrder: SortValue): Player[]
    {
        let players: Player[] = playerList.slice(0);

        players.sort((player1, player2) =>
        {
            if(player1.skill < player2.skill)
                return sortOrder === "ASC" ? -1 : 1;
            else if(player1.skill > player2.skill)
                return sortOrder === "ASC" ? 1 : -1;
            else return 0
        });

        return players;
    }

    public static getSortedPlayerListByPairing(playerList: Player[], sortOrder: SortValue) {
        let players: Player[] = playerList.slice(0);

        players.sort((player1, player2) =>
        {
            if((player1.pairing || 0) < (player2.pairing || 0))
                return sortOrder === "ASC" ? -1 : 1;
            else if((player1.pairing || 0) > (player2.pairing || 0))
                return sortOrder === "ASC" ? 1 : -1;
            else return 0
        });

        return players;
    }

    public static getPlayerSkillSumFromList(players: Player[]) : number
    {
        return players && players.length > 0 ? players.map(player => player.skill as number).reduce((total, skill) => total + skill) : 0;
    }

    public static getNewPlayerIdFromList(players: Player[]) : number
    {
        return players && players.length > 0 ? players.map(player => player.id as number).reduce((highest, current) => current > highest ? current : highest) + 1 : 1;
    }

    public static updatePlayerNameInList(playerId: number, playerName: string, players: Player[]): Player[]
    {
        let selectedPlayer: Player | undefined = players.find((currentPlayer) => currentPlayer.id === playerId);

        if (selectedPlayer)
            selectedPlayer.name = playerName;

        return players;
    }

    public static updatePlayerPositionInList(playerId: number, playerPosition: PlayerPosition, players: Player[]): Player[]
    {
        let selectedPlayer: Player | undefined = players.find((currentPlayer) => currentPlayer.id === playerId);

        if (selectedPlayer)
            selectedPlayer.position = playerPosition;

        return players;
    }

    public static updatePlayerSkillInList(playerId: number, playerSkill: PlayerSkill, players: Player[]): Player[]
    {
        let selectedPlayer: Player | undefined = players.find((currentPlayer) => currentPlayer.id === playerId);

        if (selectedPlayer)
            selectedPlayer.skill = playerSkill;

        return players;
    }

    public static updatePlayerPairingInList(playerId: number, playerPairing: PlayerPairing, players: Player[]): Player[] {
        let selectedPlayer: Player | undefined = players.find((currentPlayer) => currentPlayer.id === playerId);

        if (selectedPlayer)
            selectedPlayer.pairing = playerPairing;

        return players;
    }

    public static getRandomPlayerInList(players: Player[], position: PlayerPosition, skill?: PlayerSkill): Player | undefined {
        const playersWithSpecificPositionAndSkill: Player[] = players.filter(player => player.position === position && !player.pairing && (!skill || player.skill === skill));

        if (playersWithSpecificPositionAndSkill.length > 0) {
            return playersWithSpecificPositionAndSkill[Math.floor(Math.random() * playersWithSpecificPositionAndSkill.length)];
        }

        return undefined;
    }
}