import React from "react";
import {PlayerUtility} from "../utilities/PlayerUtility";
import PlayerTable from "./PlayerTable";
import {Player, PlayerPosition} from "../models/player";
import PlayerTableRow, {SwitchPlayerSide} from "./PlayerTableRow";
import "./PlayerOutputTable.scss";
import {Files} from "react-bootstrap-icons";
import {ResourceManager} from "../resources/resource-manager";

interface PlayerOutputTableProperties {
    team: Player[];
    teamName: string;
    switchSide: SwitchPlayerSide;
    onChangeTeam: (players: Player[]) => void;
    onChangeTeamName: (teamName: string) => void;
    onSwitchPlayer: (player: Player) => void;
}

interface PlayerOutputTableState {
}

class PlayerOutputTable extends React.Component<PlayerOutputTableProperties, PlayerOutputTableState> {
    public componentDidMount(): void {
    }

    public render(): JSX.Element {
        const goalies: Player[] = this.props.team.filter(player => player.position === PlayerPosition.Goalie);
        return (
            <>
                <h3 className="my-2 d-print-none">
                    <input className="form-control d-inline w-auto mx-1" title={ResourceManager.strings.teamName} type="text" value={this.props.teamName}
                           onChange={ (event) => this.props.onChangeTeamName(event.target.value) }/>
                    <div className="d-inline-block" title={ResourceManager.strings.teamTotalSkill}>{`(${PlayerUtility.getPlayerSkillSumFromList(this.props.team)})`}</div>
                    <div className="d-inline-block" title={ResourceManager.strings.copyTeamToClipboardForExcel}><Files className="copy-btn ml-2" onClick={() => this.copyTeamToClipBoard()}/></div>
                </h3>
                <h3 className="my-2 d-none d-print-block">{this.props.teamName}<span className="d-print-none">({PlayerUtility.getPlayerSkillSumFromList(this.props.team)})</span></h3>
                <PlayerTable tableName="outputTable" showRowNumber={true} players={this.props.team} hideGoalies={true} onChangePlayerList={(players: Player[]) => this.props.onChangeTeam(players)} switchable={true} switchSide={this.props.switchSide} onSwitchPlayer={(player: Player) => this.props.onSwitchPlayer && this.props.onSwitchPlayer(player)} />
                {
                    goalies.length === 1 && <table className="table table-bordered player-table mb-0 mt-4">
                        <tbody>
                            <PlayerTableRow rowNumber={" "} player={goalies[0]} />
                        </tbody>
                    </table>
                }
            </>
        );
    }

    private copyTeamToClipBoard(): void {
        const team: Player[] = PlayerUtility.getSortedPlayerListByName(this.props.team, "ASC");

        const element = document.createElement('textarea');
        let teamCopy: string = "";

        for(let player of team) {
            if (player.position !== PlayerPosition.Goalie)
                teamCopy += player.name + "\n";
        }

        let goalie: Player | undefined = team.find(player => player.position === PlayerPosition.Goalie);

        if (goalie)
            teamCopy += goalie.name + "\n";

        element.value = teamCopy;
        element.setAttribute('readonly', '');
        element.style.position = 'absolute';
        element.style.left = '-9999px';
        document.body.appendChild(element);
        element.select();
        document.execCommand('copy');
        document.body.removeChild(element);
    }
}

export default PlayerOutputTable;