import React from "react";
import {Player, PlayerPairing, PlayerPosition, PlayerSkill} from "../models/player";
import "./PlayerTable.scss"
import {PlayerUtility} from "../utilities/PlayerUtility";
import PlayerTableRow, {SwitchPlayerSide} from "./PlayerTableRow";
import {ResourceManager} from "../resources/resource-manager";
import {
    ArrowDown, ArrowUp
} from "react-bootstrap-icons";

export type SortValue = "ASC" | "DESC";

interface PlayerTableProperties {
    showRowNumber?: boolean;
    tableName: string;
    editable?: boolean;
    selectable?: boolean;
    switchable?: boolean;
    switchSide?: SwitchPlayerSide;
    showSelectedPlayerOnly?: boolean;
    hideGoalies?: boolean;
    players: Player[];
    onChangePlayerList: (newPlayerList: Player[]) => void;
    onSwitchPlayer?: (player: Player) => void;
}

interface PlayerTableState {
    selectedSort: SortValue
    nameSort: SortValue;
    positionSort: SortValue;
    skillSort: SortValue;
    pairingSort: SortValue;
    newPlayer: Player;
}

class PlayerTable extends React.Component<PlayerTableProperties, PlayerTableState> {
    constructor(props: PlayerTableProperties) {
        super(props);

        this.state = {
            selectedSort: "ASC",
            nameSort: "ASC",
            positionSort: "ASC",
            skillSort: "ASC",
            pairingSort: "ASC",
            newPlayer: PlayerTable.getNewPlayer(PlayerUtility.getNewPlayerIdFromList(this.props.players))
        };
    }

    public componentDidMount(): void {
        this.setState({
            newPlayer: PlayerTable.getNewPlayer(PlayerUtility.getNewPlayerIdFromList(this.props.players))
        });
    }

    public render(): JSX.Element {
        return (
            <table className="table table-striped table-bordered player-table mb-0">
                <thead>
                    <tr>
                        {
                            this.props.showRowNumber &&
                                <th className="d-print-none row-number-cell"/>
                        }
                        {
                            this.props.selectable &&
                            <th className="align-middle">
                                <input className="form-control form-control-sm" type="checkbox" checked={this.props.players.every(player => player.selected)}
                                       onChange={(event => this.onSelectAllPlayers(event.target.checked))} />
                            </th>
                        }
                        <th className="sortable-header align-middle text-nowrap" title={ResourceManager.strings.sortPlayersByName} onClick={() => this.onSortPlayerNames() }>
                            {ResourceManager.strings.name}
                            {this.state.nameSort === "ASC" ? <ArrowUp className="ml-2 d-print-none" /> : <ArrowDown className="ml-2 d-print-none" />}
                        </th>
                        <th className="sortable-header align-middle text-nowrap" title={ResourceManager.strings.sortPlayersByPosition} onClick={() => this.onSortPlayerPositions() }>
                            {ResourceManager.strings.position}
                            {this.state.positionSort === "ASC" ? <ArrowUp className="ml-2 d-print-none" /> : <ArrowDown className="ml-2 d-print-none" />}
                        </th>
                        <th className="sortable-header align-middle d-print-none text-nowrap" title={ResourceManager.strings.sortPlayersBySkill} onClick={() => this.onSortPlayerSkills() }>
                            {ResourceManager.strings.skill}
                            {this.state.skillSort === "ASC" ? <ArrowUp className="ml-2 d-print-none" /> : <ArrowDown className="ml-2 d-print-none" />}
                        </th>
                        {
                            this.props.editable &&
                            <th className="sortable-header align-middle d-print-none text-nowrap" title={ResourceManager.strings.sortPlayersByPairing} onClick={() => this.onSortPlayerPairing()}>
                                {ResourceManager.strings.pairing}
                                {this.state.pairingSort === "ASC" ? <ArrowUp className="ml-2 d-print-none"/> : <ArrowDown className="ml-2 d-print-none"/>}
                            </th>
                        }
                        { this.props.editable && <th/> }
                        { this.props.switchable && <th className="d-print-none"/> }
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.players.filter(player => (!this.props.showSelectedPlayerOnly || player.selected) &&
                                                            (!this.props.hideGoalies || player.position !== PlayerPosition.Goalie)).map((player, index) =>
                        {
                            return (
                                <PlayerTableRow
                                    key={`${this.props.tableName}-player-${player.id}`}
                                    editable={this.props.editable}
                                    selectable={this.props.selectable}
                                    switchable={this.props.switchable}
                                    switchSide={this.props.switchSide}
                                    rowNumber={this.props.showRowNumber ? (index + 1) + "" : undefined}
                                    player={player}
                                    availablePairingValues={this.getAvailablePairingValues(player.pairing)}
                                    onSelectPlayer={(selected: boolean) => this.onChangePlayerSelected(player, selected)}
                                    onChangePlayerName={(newName: string) => this.onChangePlayerName(player, newName)}
                                    onChangePlayerPosition={(newPosition: PlayerPosition) => this.onChangePlayerPosition(player, newPosition)}
                                    onChangePlayerSkill={(newSkill: PlayerSkill) => this.onChangePlayerSkill(player, newSkill)}
                                    onChangePlayerPairing={(newPairing: PlayerPairing) => this.onChangePlayerPairing(player, newPairing)}
                                    onDeletePlayer={() => this.onDeletePlayer(player)}
                                    onSwitchPlayer={() => this.props.onSwitchPlayer && this.props.onSwitchPlayer(player)}
                                />
                            );
                        })
                    }
                </tbody>
                {
                    this.props.editable &&
                    <tfoot>
                        <PlayerTableRow
                            key={`${this.props.tableName}-new-player`}
                            editable={true}
                            selectable={true}
                            player={this.state.newPlayer}
                            onChangePlayerName={(newName: string) => this.setState(prevState => ({ newPlayer: { ...prevState.newPlayer, name: newName }}))}
                            onChangePlayerPosition={(newPosition: PlayerPosition) => this.setState(prevState => ({ newPlayer: { ...prevState.newPlayer, position: newPosition }}))}
                            onChangePlayerSkill={(newSkill: PlayerSkill) => this.setState(prevState => ({ newPlayer: { ...prevState.newPlayer, skill: newSkill }}))}
                            onChangePlayerPairing={(newPairing: PlayerPairing) => this.setState(prevState => ({ newPlayer: { ...prevState.newPlayer, pairing: newPairing }}))}
                            onAddPlayer={() => this.onAddNewPlayer()}
                        />
                    </tfoot>
                }
            </table>
        );
    }

    private onChangePlayerSelected(player: Player, selected: boolean): void {
        let players: Player[] = this.props.players.splice(0);
        let selectedPlayer: Player | undefined = players.find((currentPlayer) => currentPlayer.id === player.id);

        if (selectedPlayer)
            selectedPlayer.selected = selected;

        this.props.onChangePlayerList(players);
    }

    private onChangePlayerName(player: Player, newName: string) {
        this.props.onChangePlayerList(PlayerUtility.updatePlayerNameInList(player.id, newName, this.props.players.splice(0)));
    }

    private onChangePlayerPosition(player: Player, newPosition: PlayerPosition) {
        this.props.onChangePlayerList(PlayerUtility.updatePlayerPositionInList(player.id, newPosition, this.props.players.splice(0)));
    }

    private onChangePlayerSkill(player: Player, newSkill: PlayerSkill) {
        this.props.onChangePlayerList(PlayerUtility.updatePlayerSkillInList(player.id, newSkill, this.props.players.splice(0)));
    }

    private onChangePlayerPairing(player: Player, newPairing: PlayerPairing) {
        this.props.onChangePlayerList(PlayerUtility.updatePlayerPairingInList(player.id, newPairing, this.props.players.splice(0)));
    }

    private onSelectAllPlayers(selected: boolean): void
    {
        let players: Player[] = this.props.players.splice(0);

        players.forEach(player => player.selected = selected);

        this.props.onChangePlayerList(players);
    }

    private onSortPlayerNames(): void {
        this.props.onChangePlayerList(PlayerUtility.getSortedPlayerListByName(this.props.players, this.state.nameSort));
        this.setState({ nameSort: this.state.nameSort === "ASC" ? "DESC" : "ASC" });
    }

    private onSortPlayerPositions(): void {
        this.props.onChangePlayerList(PlayerUtility.getSortedPlayerListByPosition(this.props.players, this.state.positionSort));
        this.setState({ positionSort: this.state.positionSort === "ASC" ? "DESC" : "ASC" });
    }

    private onSortPlayerSkills(): void {
        this.props.onChangePlayerList(PlayerUtility.getSortedPlayerListBySkill(this.props.players, this.state.skillSort));
        this.setState({ skillSort: this.state.skillSort === "ASC" ? "DESC" : "ASC" });
    }

    private onSortPlayerPairing(): void {
        this.props.onChangePlayerList(PlayerUtility.getSortedPlayerListByPairing(this.props.players, this.state.pairingSort));
        this.setState({ pairingSort: this.state.pairingSort === "ASC" ? "DESC" : "ASC" });
    }

    private getAvailablePairingValues(selectedValue?: PlayerPairing): PlayerPairing[] {
        const allPairingValues: PlayerPairing[] = [1, 2, 3, 4];
        const availablePairingValues: PlayerPairing[] = [];

        for(let pairing of allPairingValues)
        {
            if(this.props.players.filter(player => player.pairing === pairing).length < 2 || selectedValue === pairing)
                availablePairingValues.push(pairing);
        }

        return availablePairingValues;
    }

    private onAddNewPlayer(): void {
        const newPlayerList: Player[] = this.props.players.splice(0);
        newPlayerList.push({id: this.state.newPlayer.id, name: this.state.newPlayer.name, skill: this.state.newPlayer.skill, position: this.state.newPlayer.position, selected: false});
        this.props.onChangePlayerList(newPlayerList);
        this.setState({
            newPlayer: PlayerTable.getNewPlayer(PlayerUtility.getNewPlayerIdFromList(newPlayerList))
        }, () => {
            window.scrollTo(0,document.body.scrollHeight);
        });

    }

    private onDeletePlayer(playerToDelete: Player): void {
        this.props.onChangePlayerList(this.props.players.filter(player => player.id !== playerToDelete.id));
    }

    private static getNewPlayer(playerId: number): Player {
        return {
            id: playerId,
            name: "",
            position: PlayerPosition.Foward,
            skill: 8,
            selected: false
        };
    }
}

export default PlayerTable;